import { DeviceOrientationControls, OrbitControls } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import { useRef, useState } from "react";
import { BackSide } from "three";

export const CanvasContent = ({ motionPerm, texture, playing }: any) => {
  const cameraOffsetCalculated = useRef(false);
  const [cameraOffset, setCameraOffset] = useState(0);
  const { camera } = useThree();

  useFrame(() => {
    if (!cameraOffsetCalculated.current) {
      if (motionPerm.haveSensors && camera.rotation.y === 0) {
        setCameraOffset(camera.rotation.y - (-Math.PI / 2) * 1.5);
      } else {
        setCameraOffset(camera.rotation.y - (-Math.PI / 2) * 1.5);
        cameraOffsetCalculated.current = true;
      }
    }
  });

  return (
    <>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      {motionPerm.haveSensors ? (
        <DeviceOrientationControls />
      ) : (
        <OrbitControls enableZoom={false} rotateSpeed={-1} />
      )}
      {texture && (
        <mesh
          rotation={[
            0,
            cameraOffset,
            // (getOS() === "Android" && playing ? Math.PI / 1.7 : 0),
            0,
          ]}
        >
          <sphereGeometry args={[15, 32, 16]} />
          <meshBasicMaterial map={texture} toneMapped={false} side={BackSide} />
        </mesh>
      )}
    </>
  );
};
