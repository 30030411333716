import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./App.css";
import { Canvas } from "@react-three/fiber";
import { DefaultLoadingManager, RepeatWrapping, VideoTexture } from "three";
import { requestMotionPermission } from "./motionPermission";
import axios from "axios";
import { CanvasContent } from "./CanvasContent";
import { SignUp } from "./SignUp";
import { Rating } from "./Rating";
import { generateUUID } from "three/src/math/MathUtils";

type VideoData = {
  url: string;
  id: number;
  title: string;
  category: string;
  "start time": number;
  "stop time": number;
};

function App() {
  const [start, setStart] = useState(false);
  const [signUp] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [motionPerm, setMotionPerm] = useState({
    haveSensors: false,
    needPermissions: false,
  });
  const [videoData, setVideoData] = useState<VideoData | undefined>(undefined);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [intro, setIntro] = useState(true);
  const once = useRef(true);
  const sessionId = useRef(generateUUID());

  const sendRating = useCallback(
    async (rating: number) => {
      setShowRating(false);

      const queryString = window.location.search;
      console.log(queryString);
      // const urlParams = new URLSearchParams(queryString);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/rating`,
          {
            video_id: videoData?.id,
            rating,
            sessionId: sessionId.current,
            category: videoData?.category,
          },
          { withCredentials: false }
        );
        console.log("response", response.data);
        setVideoData((response.data.video as any as VideoData) || undefined);
        setStart(false);
      } catch (e) {
        console.error(e);
        setVideoData(undefined);
      }
    },
    [setVideoData, videoData]
  );

  const queryVideo = useCallback(
    async (
      setVideoData: React.Dispatch<React.SetStateAction<VideoData | undefined>>
    ) => {
      const queryString = window.location.search;
      console.log(queryString);
      const urlParams = new URLSearchParams(queryString);
      const productUrl = urlParams.get("p");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/category/${productUrl as string}`,
          { withCredentials: false }
        );
        console.log("response", response.data);
        setVideoData((response.data.video as any as VideoData) || undefined);
      } catch (e) {
        console.error(e);
        setVideoData(undefined);
      }
    },
    []
  );

  useEffect(() => {
    if (once.current) queryVideo(setVideoData);
    once.current = false;
  }, [queryVideo, setVideoData]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIntro(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const video = useVideo({
    url,
    setShowRating: setShowRating,
    startTime: videoData?.["start time"],
    stopTime: videoData?.["stop time"],
  });

  useEffect(() => {
    if (videoData) {
      console.log("videoData", videoData);
      setUrl(videoData.url);
      // setVideoUrl(videoData.url);
    }
  }, [videoData]);

  // const requestMotionPerm = useCallback(async (e?: any) => {
  //   const perm = await requestMotionPermission();
  //   console.log("perm", perm);
  //   setMotionPerm(perm);
  // }, []);

  // useEffect(() => {
  //   requestMotionPerm();
  // }, [requestMotionPerm]);

  // useEffect(() => {
  //   // setStart(motionPerm);
  //   if (video) {
  //     video.play();
  //   }
  // }, [video]);

  const texture = useMemo(() => {
    if (!video) return null;
    console.log("video", video);
    const texture = new VideoTexture(video);
    texture.wrapS = RepeatWrapping;
    texture.repeat.x = -1;
    return texture;
  }, [video]);

  return (
    <>
      {signUp && <SignUp />}
      <Rating
        sendRating={sendRating}
        showRating={showRating}
        sessionId={sessionId.current}
      />

      <div id="canvas-container" style={{ width: "100vw", height: "100vh" }}>
        {intro && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src="./instruction.png" alt="instruction" width="146px" />
          </div>
        )}
        {!intro && (
          <button
            style={{
              position: "absolute",
              zIndex: 1,
              visibility: !start && !signUp ? "visible" : "hidden",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "none",
              background: "none",
            }}
            onClick={(e) => {
              // requestMotionPerm(e);
              requestMotionPermission(e).then((sensor) => {
                setStart(true);
                if (video) {
                  video.muted = false;

                  console.log("PERM", video.currentTime, sensor);

                  video.play();
                  setMotionPerm(sensor);
                }
              });
            }}
          >
            <img src="./play.svg" alt="play"></img>
          </button>
        )}

        <Canvas>
          <CanvasContent
            motionPerm={motionPerm}
            texture={texture}
            playing={start}
          />
        </Canvas>
      </div>
    </>
  );
}

export default App;

export const useVideo = ({
  url,
  setShowRating: setRating,
  startTime = 0.17,
  stopTime = 30.17,
}: {
  url: string | undefined;
  setShowRating: React.Dispatch<React.SetStateAction<boolean>>;
  startTime: number | undefined;
  stopTime: number | undefined;
}) => {
  const video = useMemo<HTMLVideoElement | null>(() => {
    console.log("url", url);
    if (!url) return null;
    const videoElement = document.createElement("video");

    const loadingManager = DefaultLoadingManager;

    videoElement.src = url;
    loadingManager.itemStart(url);
    videoElement.muted = true;
    videoElement.playsInline = true;
    videoElement.autoplay = false;
    videoElement.preload = "auto";
    videoElement.crossOrigin = "anonymous";

    const onVideoLoad = () => {
      videoElement.currentTime = startTime;
      // videoElement.play();
      // setTimeout(() => {
      //   videoElement.pause();
      // }, 600);
      videoElement.removeEventListener("canplaythrough", onVideoLoad, false);
      videoElement.removeEventListener("error", onVideoError, false);
      loadingManager.itemEnd(url);
    };

    const onVideoError = () => {
      videoElement.removeEventListener("canplaythrough", onVideoLoad, false);
      videoElement.removeEventListener("error", onVideoError, false);

      loadingManager.itemEnd(url);
      loadingManager.itemError(url);
    };

    const onVideoProgress = (event: any) => {
      console.log("progress", event);
    };

    const onVideoTimeUpdate = (event: any) => {
      if (videoElement.currentTime >= stopTime) {
        console.log(
          "sign up",
          event.target,
          videoElement,
          videoElement.currentTime
        );
        setRating(true);
        videoElement.pause();
      }
    };

    videoElement.addEventListener("canplaythrough", onVideoLoad, false);
    videoElement.addEventListener("error", onVideoError, false);
    videoElement.addEventListener("progress", onVideoProgress, false);
    videoElement.removeEventListener("timeupdate", onVideoTimeUpdate, false);

    videoElement.addEventListener("timeupdate", onVideoTimeUpdate, false);

    // This is needed to cause the canplaythrough event to fire on iOS
    videoElement.load();
    return videoElement;
  }, [url, startTime, stopTime, setRating]);

  // return <video ref={videoElementRef}>Video {video.name}</video>;
  return video;
};
