import axios from "axios";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  border-radius: 20px;
  top: 20%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 330px;
  width: 80vw;
  height: auto;
  padding: 30px;
  background-color: #202125a6;
  color: #ffffff;
  border-style: none;
  font-family: "Mulish";
`;

const Wrapper2 = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  top: 20%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 330px;
  width: 80vw;
  height: auto;
  padding: 30px;
  background-color: white;
  color: black;
  border-style: none;
  font-family: "Mulish";
`;

const Header = styled.h1`
  font-size: 23px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  line-height: 30px;
`;

const RatingArea = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* Create 5 equal columns */
  grid-template-rows: 1fr 1fr; /* Create 2 equal rows */
  gap: 10px; /* Add spacing between grid items */
  margin: 20px;
`;

const Circle = styled.div`
  width: 45px;
  height: 45px;
  /* padding: 10px 5px 10px 5px; */
  gap: 14px;
  border-radius: 50px;
  border: 1px;
  border-style: solid;
  color: #ffffff89;
`;
const Number = styled.p`
  color: #ffffff;
  //styleName: Header Style;
  font-family: Mulish;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  margin: 7px;
`;

const Explainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create 5 equal columns */
  grid-template-rows: 1fr; /* Create 2 equal rows */
  margin: 20px;
  color: #ffffff89;
  font-size: 12px;
`;

export const Button = styled.button`
  width: 310px;
  height: 72px;
  gap: 15.09px;
  border-radius: 8px;
  background: #ffffff;
  color: #621b4d;
  border: none;
  left: 50%;
  transform: translate(-50%, 0);
  position: relative;

  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

const Button2 = styled.button`
  width: 320px;
  height: 72px;
  gap: 15.09px;
  border-radius: 8px 0px 0px 0px;
  background: #621b4d;
  color: #ffffff;
  border: none;

  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

const SubHeader = styled.h2`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
  margin: 10px;
`;

const EmailText = styled.p`
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

export const Rating = ({
  sendRating,
  showRating,
  sessionId,
}: {
  sendRating: (rating: number) => void;
  showRating: boolean;
  sessionId: string;
}) => {
  const [selected, setSelected] = useState(0);
  const count = useRef(1);
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (selected !== 0) {
      console.log("selected", selected);
    }
  }, [selected]);

  const handleClick = (item: number) => {
    const num = item;
    console.log("num", num);
    setSelected(num);
  };

  const Objects = () => {
    var indents = [];
    for (let i = 1; i <= 10; i++) {
      indents.push(
        <Circle
          key={i}
          // eslint-disable-next-line no-loop-func
          onClick={() => handleClick(i)}
        >
          <Number>{i}</Number>
        </Circle>
      );
    }
    return <>{indents}</>;
  };
  console.log("showRating", showRating);

  if (!showRating) {
    return <> </>;
  }

  if (selected === 0) {
    return (
      <Wrapper>
        <Header>Rate your anxiety level of this environment to proceed</Header>
        <RatingArea>
          <Objects />
        </RatingArea>
        <Explainer>
          <p>1 = No Anxiety</p>
          <p style={{ justifySelf: "end" }}>10 = Extreme</p>
        </Explainer>
      </Wrapper>
    );
  } else {
    if (count.current < 3) {
      return (
        <Wrapper>
          <Header>Thanks for your rating! </Header>
          <p style={{ textAlign: "center" }}>
            Here is your next virtual environment based on this rating{" "}
          </p>
          <Button
            onClick={() => {
              count.current++;
              sendRating(selected);
              setSelected(0);
            }}
          >
            Start Environment
          </Button>
        </Wrapper>
      );
    } else {
      return (
        <Wrapper2>
          <img
            src="./character.png"
            alt="logo"
            style={{ width: "90px", alignSelf: "center" }}
          ></img>
          <Header>{"Receive your free personalised report"}</Header>
          <SubHeader>
            oVRcome is the clinically proven way to get anxiety, stress and fear
            out of your life
          </SubHeader>
          <div
            style={{
              outline: "1px solid #49323e",
              width: "100%",
              height: "50px",
              borderRadius: "10px",
              padding: "5px",
              paddingRight: "0px",
              paddingLeft: "0px",
              backgroundColor: "#ffffff",
            }}
          >
            <EmailText
              style={{ margin: "0px", marginLeft: "5px", color: "#807078" }}
            >
              Email address
            </EmailText>
            <input
              type="text"
              autoFocus
              style={{}}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button2
            style={{
              marginTop: "20px",
              width: "100%",
              height: "50px",
              borderRadius: "10px",
            }}
            onClick={async () => {
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_API_URL}/email`,
                  {
                    email,
                    sessionId,
                  },
                  { withCredentials: false }
                );
                console.log("response", response.data);
                console.log("send email");
              } catch (e) {
                console.error(e);
              }
              // setSignUp(false);
            }}
          >
            Sign me up
          </Button2>
          {/* <p>I'll do this later</p> */}
        </Wrapper2>
      );
    }
  }
};
