import axios from "axios";
import { useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  border-radius: 20px;
  top: 20%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 330px;
  width: 80vw;
  height: 475px;
  padding: 20px;
  background-color: white;
  color: #49323e;
  border-style: solid;
  border-color: #49323e;
  border-width: 0.5px;
  font-family: "Mulish";
`;

const Header = styled.h1`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  line-height: 38px;
`;

const SubHeader = styled.h2`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
  margin: 10px;
`;

const EmailText = styled.p`
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

const Button = styled.button`
  width: 320px;
  height: 72px;
  gap: 15.09px;
  border-radius: 8px;
  background: #621b4d;
  color: #ffffff;
  border: none;

  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

export const SignUp = () => {
  const [email, setEmail] = useState("");

  return (
    <Wrapper>
      <Header>Receive your free personalised report</Header>
      <SubHeader>
        Please enter your email to receive a free personalized report based on
        your answers.
      </SubHeader>
      <div
        style={{
          outline: "1px solid #49323e",
          width: "100%",
          height: "50px",
          borderRadius: "10px",
          padding: "5px",
          paddingRight: "0px",
          paddingLeft: "0px",
        }}
      >
        <EmailText style={{ margin: "0px", marginLeft: "5px" }}>
          Email address
        </EmailText>
        <input
          type="text"
          autoFocus
          style={{}}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <Button
        style={{
          marginTop: "20px",
          width: "100%",
          height: "50px",
          borderRadius: "10px",
        }}
        onClick={async () => {
          try {
            await axios.post(
              "https://sqkippwnsl.execute-api.ap-southeast-2.amazonaws.com",
              {
                email,
                // groupId: product ? product.groupId : "undefined",
              }
            );
          } catch (e) {
            console.error(e);
          }
          // setSignUp(false);
        }}
      >
        Sign me up
      </Button>
      {/* <p>I'll do this later</p> */}
    </Wrapper>
  );
};
